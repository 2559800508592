<template>
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>

        <div class="letwecare-breadcrumb">

          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            :style="{ height: '63px', paddingLeft: 0 }"
            :title="formData.name"
            @back="backToFormsPage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout class="app-forms-editor-page app-layout-nested">
    <!-- Content -->
    <a-layout-content class="layout-nested-content">
      <div id="content-scroller" class="content__body fluid">
        <a-spin :spinning="loading || submitting" tip="更新中">
          <div class="__wrapper">
            <a-form layout="vertical">
              <div
                v-for="(form, formIdx) in formState.form_list"
                @click="onForm(formIdx)"
                class="form-section-card"
              >
                <span class="form-section-focus" v-if="formIdx==onFormIdx">
                  <span class="mdi mdi-pencil" :style="{ color: '#1890FF' }"></span>
                </span>
                
                <div class="form-section-actions">
                  <a-tooltip>
                    <template #title>
                      <span>刪除區塊</span>
                    </template>
                    <a-button
                      class="action-btn"
                      type="link" shape="circle"
                      @click="deleteFormSection(formIdx)"
                      :style="{ color: '#757575' }"
                    >
                      <template #icon>
                        <span class="mdi mdi-close" />
                      </template>
                    </a-button>
                  </a-tooltip>
                </div>

                <div
                  class="form-sidebar-actions"
                  v-if="formIdx == onFormIdx"
                  :style="sidebarAction.offsetStyle"
                >
                  <a-row>
                    <a-col :span="24" align="center">
                      <a-tooltip placement="right">
                        <template #title>
                          <span>新增欄位</span>
                        </template>
                        <a-button
                          class="action-btn"
                          type="link" shape="circle"
                          @click="addFormSectionField(formIdx)"
                          :style="{ color: '#212121' }"
                        >
                          <template #icon>
                            <span class="mdi mdi-plus-circle-outline" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-col>

                    <a-col :span="24" align="center">
                      <a-tooltip placement="right">
                        <template #title>
                          <span>新增區塊</span>
                        </template>
                        <a-button
                          class="action-btn"
                          type="link" shape="circle"
                          @click="addFormSection(formIdx)"
                          :style="{ color: '#212121' }"
                        >
                          <template #icon>
                            <span class="mdi mdi-view-agenda-outline" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-col>

                    <a-col :span="24" align="center" v-if="formIdx != 0">
                      <a-tooltip placement="right">
                        <template #title>
                          <span>上移一格</span>
                        </template>
                        <a-button
                          class="action-btn"
                          type="link" shape="circle"
                          @click="moveUp(formIdx)"
                          :style="{ color: '#212121' }"
                        >
                          <template #icon>
                            <span class="mdi mdi-arrow-up" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-col>

                    <a-col :span="24" align="center" v-if="formIdx != formState.form_list.length-1">
                      <a-tooltip placement="right">
                        <template #title>
                          <span>下移一格</span>
                        </template>
                        <a-button
                          class="action-btn"
                          type="link" shape="circle"
                          @click="moveDown(formIdx)"
                          :style="{ color: '#212121' }"
                        >
                          <template #icon>
                            <span class="mdi mdi-arrow-down" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                </div>

                <div class="form-section-title" :span="24">
                  <a-input
                    v-model:value="form.section_title"
                    placeholder="區塊標題"
                    :bordered="false"
                  />
                </div>

                <div class="form-section-fields">
                  <a-row
                    v-for="(field, fieldIdx) in form.fields"
                    :gutter="12"
                  >
                    <a-col :span="14">
                      <a-form-item label="欄位名稱">
                        <a-input
                          v-model:value="field.name"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6">
                      <a-form-item label="填答類型">
                        <a-select
                          v-model:value="field.type"
                          @change="changeFieldItemType(formIdx, fieldIdx)"
                        >
                          <a-select-option
                            v-for="item in formFieldItemTypeOptions"
                            :value="item.value"
                          >
                            <span role="img" :class="`icon mdi ${item.icon}`" :style="{ color: '#757575' }" />
                            &nbsp;&nbsp;{{ item.label }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="2">
                      <a-form-item label=" ">
                        <a-tooltip>
                          <template #title>
                            <span>必填</span>
                          </template>
                          <a-switch v-model:checked="field.required" />
                        </a-tooltip>
                      </a-form-item>
                    </a-col>
                    <a-col :span="2">
                      <a-form-item label=" ">
                        <a-tooltip>
                          <template #title>
                            <span>刪除</span>
                          </template>
                          <a-button type="link" shape="circle" @click="deleteFormSectionField(formIdx, fieldIdx)">
                            <template #icon>
                              <span class="mdi mdi-delete" :style="{ color: '#757575' }"></span>
                            </template>
                          </a-button>
                        </a-tooltip>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <template v-if="field.type == 'radio'">
                        <a-col :span="24">
                          <a-form-item>
                            <div
                              class="ml-4 mb-2"
                              v-for="(selectionItem, selectionItemIdx) in field.selection_items"
                            >
                              <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                              <a-input
                                v-model:value="selectionItem.label"
                                :placeholder="'選項 '+(selectionItemIdx+1)"
                                :disabled="updating"
                                :bordered="false"
                                :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                              />
                              <a-tooltip placement="right">
                                <template #title>
                                  <span>刪除選項</span>
                                </template>
                                <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteFormFieldSelectionItem(formIdx, fieldIdx, selectionItemIdx)">
                                  <template #icon>
                                    <span class="mdi mdi-close" />
                                  </template>
                                </a-button>
                              </a-tooltip>
                            </div>

                            <div class="ml-4">
                              <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                              <a-button
                                @click="addFormFieldSelectionItem(formIdx, fieldIdx)"
                                type="text" size="small"
                              >新增選項</a-button>
                            </div>
                          </a-form-item>
                        </a-col>
                      </template>

                      <template v-if="field.type == 'checkbox'">
                        <a-col :span="24">
                          <a-form-item>
                            <div
                              class="ml-4 mb-2"
                              v-for="(selectionItem, selectionItemIdx) in field.selection_items"
                            >
                              <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                              <a-input
                                v-model:value="selectionItem.label"
                                :placeholder="'選項 '+(selectionItemIdx+1)"
                                :disabled="updating"
                                :bordered="false"
                                :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                              />
                              <a-tooltip placement="right">
                                <template #title>
                                  <span>刪除選項</span>
                                </template>
                                <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteFormFieldSelectionItem(formIdx, fieldIdx, selectionItemIdx)">
                                  <template #icon>
                                    <span class="mdi mdi-close" />
                                  </template>
                                </a-button>
                              </a-tooltip>
                            </div>

                            <div class="ml-4">
                              <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                              <a-button
                                @click="addFormFieldSelectionItem(fieldIdx, fieldIdx)"
                                type="text" size="small"
                              >新增選項</a-button>
                            </div>
                          </a-form-item>
                        </a-col>
                      </template>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-form>
          </div>
        </a-spin>
      </div>
    </a-layout-content>
    <!-- Content -->

    <!-- Sider -->
    <a-layout-sider
      v-if="!device.mobile"
      class="form-editor-sider layout-nested-sider sider--wide"
      :style="{ padding: '20px' }"
    >
      <a-form
        :model="formData"
        layout="vertical"
        autocomplete="off"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
              label="表單名稱"
              name="name"
              :rules="[{ required: true, message: '請輸入表單名稱' }]"
            >
              <a-input
                class="form-name"
                v-model:value="formState.name"
                :bordered="false"
                :disabled="submitting"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="表單說明"
              name="description"
            >
              <a-input
                v-model:value="formState.description"
                :disabled="submitting"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="填表身份"
              name="bind_roles"
            >
              <a-select
                v-model:value="formState.bind_roles"
                :options="roleOptions"
                :disabled="submitting"
                mode="multiple"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="備註 (不會呈現至使用者畫面)"
              name="note"
            >
              <a-textarea
                v-model:value="formState.note"
                :auto-size="{ minRows: 3 }"
                :disabled="submitting"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formState.updated_datetime">
            <a-form-item
              label="最新修改時間"
              name="updated_datetime"
            >
              <a-input
                :value="DateUtil.formatDatetime(formState.updated_datetime)"
                :bordered="false"
                readonly
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <div class="sider-actions">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-button
              @click="openFormPreview"
              size="large"
              :disabled="submitting"
              :style="{ width: '100%' }"
            >預覽</a-button>
          </a-col>
          <a-col :span="12">
            <a-button
              @click="updateForm"
              type="primary" size="large"
              :loading="submitting"
              :style="{ width: '100%' }"
            >保存</a-button>
          </a-col>
        </a-row>
      </div>
    </a-layout-sider>
  </a-layout>

  <FormPreview
    :visible="formPreview.visible"
    :data="formPreview.data"
    @close="handleFormPreviewClose"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import _ from 'lodash'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import RoleUtil from '@/utils/RoleUtil'
import CopyUtil from '@/utils/CopyUtil'
import DateUtil from '@/utils/DateUtil'
import FormPreview from './components/FormPreview.vue'

export default defineComponent({
  components: {
    FormPreview
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const appStore = useAppStore();

    /* Loading */
    const loading = ref(false);
    const submitting = ref(false);
    const loadingTip = ref('');

    /* Data */
    const formData = ref({});

    /* Form State */
    const formState = ref({});
    const formFieldItem = {
      name: '',
      type: 'text-field',
      selection_items: [],
      required: false
    }
    const formListItem = {
      section_title: '',
      fields: [formFieldItem]
    }

    const changeFieldItemType = (formIdx, fieldIdx) => {
      const type = formState.value.form_list[formIdx].fields[fieldIdx].type;

      console.log(formState.value.form_list[formIdx].fields[fieldIdx].selection_items)

      if (type == 'radio') {
        if (!formState.value.form_list[formIdx].fields[fieldIdx].selection_items.length) {
          formState.value.form_list[formIdx].fields[fieldIdx].selection_items.push({
            label: ''
          });
        }
      }
      else if (type == 'checkbox') {
        if (!formState.value.form_list[formIdx].fields[fieldIdx].selection_items.length) {
          formState.value.form_list[formIdx].fields[fieldIdx].selection_items.push({
            label: ''
          });
        }
      }

      console.log(formState.value.form_list[formIdx].fields);
    }

    /* Form Options */
    // sidebar actions
    const onFormIdx = ref(0);
    const sidebarAction = ref({
      offset: 0,
      offsetStyle: {
        top: 0
      }
    });

    // bind role
    const roleOptions = ref([]);
    RoleUtil.list().forEach(item => {
      if (item.id < 4) {
        roleOptions.value.push({
          label: item.roleNameCH,
          value: item.id
        });
      }
    });

    /* Api */
    const getFormData = (async () => {
      loadingTip.value = '載入中';
      loading.value = true;

      const response = await api.v1.form.findOne(route.query.id);
      formData.value = { ...response.data.result };
      formState.value = { ...response.data.result };

      console.log(response.data.result);

      if (formData.value.form_list.length == 0) {
        formState.value.form_list.push(CopyUtil.iterateObjectDeepClone(formListItem));
      }

      loading.value = false;

      console.log(formState.value)
    });

    const updateForm = (async () => {
      loadingTip.value = '更新中';
      submitting.value = true;

      if (formState.value.form_list.length == 0 ||
        (formState.value.form_list.length>0 && formState.value.form_list[0]['section_title']=='') 
      ) {
        formState.value.form_list = [];
      }

      try {
        await api.v1.form.updateOne(route.query.id, formState.value);
        message.success('更新表單成功');
      } catch (error) {
        console.log(error);
        message.error('更新表單發生錯誤，請稍後再試');
      } finally {
        submitting.value = false;
      }
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: `/app/admin/forms`,
        breadcrumbName: '表單管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      await getFormData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/admin/forms/editor`,
        breadcrumbName: formData.value.name
      });
    });

    return {
      device,

      /* Store */
      appStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Loading */
      loading,
      submitting,

      /* Data */
      formData,

      /* Form State */
      formState,
      formFieldItem,
      formListItem,
      formFieldItemTypeOptions: ref([{
        label: '數字',
        value: 'number',
        icon: 'mdi-numeric'
      }, {
        label: '簡答文字',
        value: 'text-field',
        icon: 'mdi-text-short'
      }, {
        label: '段落',
        value: 'textarea',
        icon: 'mdi-text-long'
      }, {
        label: '單選題',
        value: 'radio',
        icon: 'mdi-radiobox-marked'
      }, {
        label: '多選題',
        value: 'checkbox',
        icon: 'mdi-checkbox-marked'
      }]),
      changeFieldItemType,

      /* Form Options */
      onFormIdx,
      sidebarAction,
      roleOptions,

      /* Api */
      getFormData,
      updateForm,

      /* Utils */
      CopyUtil,
      DateUtil
    }
  },
  mounted() {
    document.getElementById('content-scroller').addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    document.getElementById('content-scroller').removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      /* Form Preview */
      formPreview: {
        visible: false,
        data: {}
      }
    }
  },
  methods: {
    /* Router */
    backToFormsPage() {
      this.$router.push({ name: 'AppForms' });
    },

    /* Form Actions */
    handleScroll: _.debounce(function() {
      let scrollTop = document.getElementById('content-scroller').scrollTop

      let formSectionDom = document.getElementsByClassName('form-section-card')[this.onFormIdx];
      let formSectionOffsetTop = 0;
      while (formSectionDom) {
        formSectionOffsetTop += (formSectionDom.offsetTop + formSectionDom.clientTop);
        formSectionDom = formSectionDom.offsetParent;
      }

      let offset = scrollTop - formSectionOffsetTop + 84;
      if (offset > 0) {
        this.sidebarAction.offsetStyle.top = offset.toString() + 'px';
      }
    }, 200),
    onForm(formIdx) {
      if (formIdx == this.onFormIdx) return

      this.onFormIdx = formIdx;
      this.sidebarAction.offsetStyle.top = 0;
      document.getElementById('content-scroller').addEventListener('scroll', this.handleScroll);
    },
    moveUp(formIdx) {
      [this.formState.form_list[formIdx-1], this.formState.form_list[formIdx]] = [this.formState.form_list[formIdx], this.formState.form_list[formIdx-1]]
    },
    moveDown(formIdx) {
      [this.formState.form_list[formIdx+1], this.formState.form_list[formIdx]] = [this.formState.form_list[formIdx], this.formState.form_list[formIdx+1]]
    },
    addFormSectionField(formIdx) {
      this.formState.form_list[formIdx].fields.push(CopyUtil.iterateObjectDeepClone(this.formFieldItem));
    },
    deleteFormSectionField(formIdx, fieldIdx) {
      this.formState.form_list[formIdx].fields.splice(fieldIdx, 1);
    },
    addFormSection(formIdx) {
      this.formState.form_list.splice(
        formIdx+1,
        0,
        CopyUtil.iterateObjectDeepClone(this.formListItem)
      );

      setTimeout(() => {
        this.onForm(formIdx + 1);
      }, 1);
    },
    deleteFormSection(formIdx) {
      this.formState.form_list.splice(formIdx, 1);
    },
    addFormFieldSelectionItem(formIdx, fieldIdx) {
      this.formState.form_list[formIdx].fields[fieldIdx].selection_items.push({
        label: ''
      });
    },
    deleteFormFieldSelectionItem(formIdx, fieldIdx, selectionItemIdx) {
      this.formState.form_list[formIdx].fields[fieldIdx].selection_items.splice(selectionItemIdx, 1);
    },

    /* Form Preview */
    openFormPreview() {
      this.formPreview.data = this.formState;
      this.formPreview.visible = true;
    },
    handleFormPreviewClose() {
      this.formPreview.visible = false;
    }
  }
})
</script>

<style lang="scss">
.app-forms-editor-page {
  .__wrapper {
    width: 100%;
    min-height: 200px;
    max-width: 700px;
    margin: 12px auto;
  }

  .form-section-card {
    background: #fff;
    border-radius: 2px;
    padding: 40px 24px 24px;
    margin-bottom: 20px;
    position: relative;

    .form-section-focus {
      letter-spacing: .0625rem;
      font-weight: 500;
      position: absolute;
      left: 15px;
      top: 10px;

      // width: 0;
      // height: 0;
      // border: 10px solid transparent;
      // border-radius: 3px;
      // border-top-color: #424242;
      // border-left-color: #424242;
    }

    .form-section-actions {
      .action-btn {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .form-sidebar-actions {
      position: absolute;
      right: -48px;
      top: 0;
      border-radius: 4px;
      background: white;
      box-shadow: 0 20px 100px rgba(0,0,0,0.08);
      width: 35px;
      transition: top .3s ease-in-out;
      padding: 8px 0;
    }

    .form-section-title {
      margin-bottom: 28px;

      & input {
        color: #212121;
        font-size: 1.3rem;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }

  .form-editor-sider {
    height: 100%;
    position: relative;

    .form-name {
      color: #212121;
      font-size: 1.5rem;
      letter-spacing: 0.05rem;
    }

    .sider-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
    }
  }
}
</style>